import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import Bugsnag from "@bugsnag/js";
import { E_GlobalDomains } from "@shared/constants";
import { catchError, Observable, of, switchMap, tap } from "rxjs";
import { HttpService } from "../services/http.service";
import { environment } from "src/environments/environment";
import { JWTService } from "../services/jwt.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private _httpService: HttpService, private _jwtService: JWTService) {}
  private _request: HttpRequest<any>;

  private _isVpcGraphCall() {
    return (
      this._request.url.includes("graphql.dentr") ||
      this._request.url.includes(`graphql.${E_GlobalDomains.SANDBOX}`) ||
      this._request.url.includes(`graphql.${E_GlobalDomains.PRODUCTION}`)
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._request = request;
    const requestTime = Date.now();

    return next.handle(request).pipe(
      switchMap((res: HttpEvent<any>) => {
        if ("body" in res && res.body?.errors && this._isVpcGraphCall()) {
          console.error("Errors returned in VPC Graph call", res.body.errors);
          Bugsnag.leaveBreadcrumb("Errors returned in VPC Graph call", res.body.errors);
          Bugsnag.notify(new Error("Errors returned in VPC Graph call"));
          throw new Error("Errors returned in VPC Graph call");
        }
        return of(res);
      }),
      catchError((error) => {
        if (this._isVpcGraphCall()) {
          console.error("Caught error in VPC Graph call - retrying request on non VPC url", error);
          Bugsnag.leaveBreadcrumb("Caught error in VPC Graph call - retrying request on non VPC url", error);
          Bugsnag.notify(new Error("Caught error in VPC Graph call - retrying request on non VPC url"));
          this._httpService.revertToOldGraphUrl();
          const newRequest = request.clone({
            url: environment.GRAPH_URL,
          });
          return next.handle(newRequest);
        }
        throw error;
      }),
      tap({
        next: () => {
          const responseTime = Date.now();
          const duration = responseTime - requestTime;
          const isPip = this._jwtService.isPip();
          const sessionId = this._jwtService.getJWT()?.sid;

          const logSlow = (durationThreshold: number, message: string): void => {
            if (duration > durationThreshold) {
              Bugsnag.leaveBreadcrumb(message, { url: request.url, duration, sid: sessionId });
              Bugsnag.notify(new Error(message));
            }
          };

          logSlow(isPip ? 2500 : 10000, `SlowHttp${isPip ? " for PiP" : ""}`);
        },
        error: (error: HttpErrorResponse) => {
          Bugsnag.leaveBreadcrumb("HttpError", { url: error.url, status: error.status, message: error.message, error }); // Leave details about the error in the breadcrumb
          Bugsnag.notify(new Error(error.status === 503 ? "HttpTimeoutError" : "HttpError"));
        },
      })
    );
  }
}
